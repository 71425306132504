<template>
  <v-row>
    <v-col cols="12" class="mb-6 ml-3 d-flex justify-space-between align-center">
      <h2 class="mt-6  title">{{ $t('user.crm.title') }}</h2>
      <div class="mt-6 mr-5 crm-topmenu">
        <v-btn :to="{name: 'users'}" class="rounded-0" elevation="1">
          {{ $t('user.crm.buttons.users') }}
        </v-btn>
        <v-btn v-if="mxware.can('role-show')" :to="{name: 'roles'}" class="rounded-0" elevation="1">
          {{ $t('user.crm.buttons.role') }}
        </v-btn>
        <v-btn v-if="mxware.can('permission-show')" :to="{name: 'permissions'}" class="rounded-0 mr-2" elevation="1">
          {{ $t('user.crm.buttons.permissions') }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>

<style lang="scss">
.crm-topmenu {
  .v-btn--active {
    background-color: #fc8b5d !important;
    &::before {
      opacity: 0 !important;
    }
  }
}
</style>
